<template>
  <div class="app-contianer">
    <div class="page-bg">
      <div class="bg"></div>
    </div>
    <div id="app-content">
      <div class="page-item phone-box">
        <input v-model="phone" placeholder="请输入手机号码" maxlength="11" onkeyup="value=value.replace(/[^\d]/g,'')" />
        <div class="page-icon phone"></div>
      </div>

      <!-- <div class="radio-box">
        <div class="title f40"><strong>充值类型</strong></div>
        <div class="content">
          <span class="item mainColor mainBorder">
            <span class="dec mainBgColor">推荐</span>
            <span>特惠慢充</span>
          </span>
          <span class="item">快速充值</span>
          <span class="item hide"></span>
        </div>
      </div> -->

      <div class="radio-box productType">
        <div class="title f40"><strong>运营商</strong></div>
        <div class="content">
          <span class="item " :class="item.value === productType ? 'mainColor mainBorder':''" v-for="item in productMap"
            :key="item.value" @click="changeType(item)">
            {{item.label}}
          </span>
        </div>
      </div>

      <div class="radio-box product">
        <div class="title f40"><strong>充值金额</strong></div>
        <div class="content">
          <span class="item" :class="productId === item.id ? 'mainColor mainBorder':''" v-for="item in productList"
            :key="item.id" @click="productId = item.id">
            <span class="dec mainBgColor">立减{{item.faceValue - item.cost}}元</span>
            <div class="f40">{{item.productName}}</div>
            <div class="f34">折扣价:{{item.cost}}元</div>
          </span>
        </div>
      </div>

      <div class="payType">
        <div class="title f40"><strong>支付方式</strong></div>
        <div v-if="versions === 'WeChat' && wxPay === 'Y'" class="item" @click="payTrxCode = 'TC003'">
          <img src="../../../assets/user/wxzf.png" />
          <span class="text">微信</span>
          <van-icon :class="payTrxCode === 'TC003' ? 'mainBgColor mainBorder':''" name="success" />
        </div>
        <div v-if="zfbPay === 'Y'" class="item" @click="payTrxCode = 'TC011'">
          <img src="../../../assets/user/zfb.png" />
          <span class="text">支付宝</span>
          <van-icon :class="payTrxCode === 'TC011' ? 'mainBgColor mainBorder':''" name="success" />
        </div>
      </div>

      <div class="tip-box ">
        <div class="title f40"><strong>温馨提醒</strong></div>
        <div class="content f38">
          <p>【充值提醒】<span class="red">全国三网慢充，1~72小时内到账，停机欠费可以正常到账。空号、携号转网或是虚拟运营商不支持充值（有可能拆分成30/50/100面值到账）</span></p>
          <!-- <p>【充值成功】充值成功将会收到公众号的充值成功提示，短信通知可能出现收不到个别情况。</p> -->
          <p>【充值失败】如充值失败，会自动退回到对应账户，请注意查收；如有疑问，可以联系平台客服进行咨询。</p>
          <p>【充值失败】<span class="red">充值前请认真核对充值号码</span>，一经提交成功
            概不退款。</p>
        </div>
      </div>

      <div class="footer-box">
        <div class="icon" @click="goPage('/contactus')"><span class="page-icon contact"></span></div>
        <div class="icon" @click="goPage('/tariffeRecord')"><span class="page-icon record"></span></div>
        <div class="rechargeBut f40 mainBgColor" @click="verify()">立即充值</div>
      </div>

      <!-- 充值确认弹框 -->
      <van-popup v-model="showVerify" class="verify-popup">
        <div class="popup-header mainBgColor">
          手机号确认
          <van-icon class="icon" name="cross" @click="showVerify = false" />
        </div>
        <div class="popup-content">
          <div class="phone">{{phone}}</div>
          <div class="tip-box ">
            <div class="title f38"><strong>温馨提示: </strong></div>
            <div class="content f36">
              <p>1、急用勿充！话费将在<span class="red">72小时之内</span>到账，<span class="red">月初高峰期会超时</span>，请您知晓！</p>
              <p>2、请确认号码无误后再支付，<span class="red">充值成功后不支持退换</span> </p>
              <p>3、携号转网、和虚拟号码，暂不支持下单！</p>
            </div>
          </div>
          <div class="submitBut" @click="submit()">继续充值</div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { mallApi } from '@/api'
import theAlipay from '@/components/Alipay.js'
import { mapState, mapActions } from 'vuex'
import wx from 'weixin-js-sdk'



const productMap = Object.freeze([
  { value: 'CHINA_UNICOM', label: '联通' },
  { value: 'CHINA_TELECOM', label: '电信' },
  { value: 'CHINA_MOBLIE', label: '移动' },
])

export default {
  name: 'tariffe',
  data() {
    return {
      productMap: productMap,
      showVerify: false,
      phone: '',
      payTrxCode: '',
      productType: 'CHINA_UNICOM',
      productList: [],
      productId: '',
      loading: false,
      wxPay: 'N',
      zfbPay: 'N'
    }
  },
  computed: {
    ...mapState('user', ['versions']),
  },
  created() {
    this.getProductList()
  },
  methods: {
    // 获取产品列表
    getProductList() {
      let params = {
        trxCode: 'XF330',
        productType: this.productType
      }
      mallApi(params).then(res => {
        if (res.rspCode === '0000') {
          this.productList = res.data.productList
          this.productId = res.data.productList[0].id
          this.wxPay = res.data.WECHATPAY
          this.zfbPay = res.data.ALIPAY
        }
      })
    },

    // 提交订单
    submit() {
      if (this.loading) return
      this.loading = true
      let toast = Toast.loading({
        duration: 0, // 持续展示
        forbidClick: true,
        message: '受理中...',
      })

      let params = {
        trxCode: 'XF354',
        productId: this.productId,
        phone: this.phone,
        payTrxCode: this.payTrxCode
      }

      mallApi(params).then(res => {
        const { data, rspCode, rspMsg } = res
        toast.clear()
        this.loading = false
        this.showVerify = false
        if (rspCode === 'P000') {
          if (params.payTrxCode === 'TC011') {
            theAlipay({ url: data.codeUrl })
          }
          if (params.payTrxCode === 'TC003') {
            var codeUrl = data.payInfo
            wx.chooseWXPay({
              timestamp: codeUrl.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
              nonceStr: codeUrl.nonceStr, // 支付签名随机串，不长于 32 位
              package: codeUrl.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
              signType: codeUrl.signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
              paySign: codeUrl.paySign, // 支付签名
              success: function (res) {
                this.$toast.success('支付成功')
              },
              fail(err) {
                this.$toast.fail(err.errMsg)
              },
            })
          }
        } else {
          this.$toast(rspMsg)
        }

      })
    },

    // 充值确实弹框
    verify() {
      if (!this.phone) {
        this.$toast('请先输入手机号码')
        return
      }

      let reg = /^[1][3,4,6,5,7,8,9][0-9]{9}$/;
      if (!reg.test(this.phone)) {
        this.$toast('请输入正确的手机号码')
        return;
      }

      if (this.wxPay === 'N' && this.zfbPay === 'N') {
        this.$toast('无可用支付渠道, 请联系服务商~')
        return;
      }

      if (!this.payTrxCode) {
        this.$toast('请选择支付方式')
        return;
      }


      this.showVerify = true
    },

    changeType(item) {
      this.productType = item.value
      this.getProductList()
    },

    // 页面跳转
    goPage(path) {
      this.$router.push({ path: path })
    },

  }
}
</script>

<style lang='less' scoped>
.phone-box {
  padding: 24px 12px;
  display: flex;
  justify-content: space-between;

  input {
    font-size: 4.2vw;
  }
}
.page-icon.phone {
  width: 21px;
  height: 28px;
  background-position: -128px -151px;
  margin-right: 6px;
}
.radio-box {
  margin: 12px;
  margin-top: 24px;

  .title {
    margin-bottom: 18px;
  }

  .content {
    display: flex;
    justify-content: space-between;
  }

  .item {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    text-align: center;
    padding: 12px 0;
    border-radius: 8px;
    background: #fff;
    font-size: 3.8vw;
    color: #777;
    border: 1px solid #fff;
  }
  .dec {
    position: absolute;
    top: -8px;
    left: -1px;
    font-size: 3vw;
    color: #fff;
    padding: 0 8px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
.radio-box.product {
  .content {
    flex-wrap: wrap;
  }
  .item {
    width: 48%;
    margin-bottom: 24px;
  }
}
.radio-box.productType {
  .item {
    flex: 1;
    margin: 0px 6px;
  }
}
.tip-box {
  margin: 12px;
  margin-top: 24px;

  .title {
    margin-bottom: 12px;
  }
  .content {
    color: #666;
  }
  p {
    margin-bottom: 6px;
  }
}
.footer-box {
  position: fixed;
  bottom: 0;
  display: flex;
  width: 100%;
  padding: 12px;
  box-sizing: border-box;

  .icon {
    width: 46px;
    height: 46px;
    background: #fff;
    text-align: center;
    line-height: 46px;
    border-radius: 10px;
    margin-right: 12px;
  }
  .page-icon {
    width: 22px;
    height: 22px;
    vertical-align: middle;
  }
  .page-icon.contact {
    background-position: -132px -86px;
  }
  .page-icon.record {
    background-position: -167px -86px;
  }
  .rechargeBut {
    flex: 1;
    text-align: center;
    line-height: 46px;
    color: #fff;
    border-radius: 10px;
  }
}
.verify-popup {
  width: 80vw;
  border-radius: 10px;

  .popup-header {
    position: relative;
    text-align: center;
    padding: 12px;
    color: #fff;

    .icon {
      position: absolute;
      right: 12px;
      top: 50%;
      font-size: 16px;
      margin-top: -8px;
    }
  }

  .phone {
    background: #f4f4f4;
    text-align: center;
    padding: 12px;
    border-radius: 10px;
    font-weight: bold;
    letter-spacing: 2px;
    font-size: 4vw;
    margin: 12px;
  }

  .tip-box .title {
    color: #444;
  }
}
.payType {
  margin: 4vw;
  font-size: 4vw;

  > .title {
    padding-bottom: 8px;
  }

  .item {
    display: flex;
    align-items: center;
    padding: 6px 0;

    img {
      width: 7vw;
      margin-right: 2vw;
    }
    .text {
      flex: 1;
    }
    .van-icon-success {
      padding: 0.5vw;
      color: #fff;
      border: 1px solid #999;
      border-radius: 50%;
    }
  }
  .item.active {
    .van-icon-success {
      background: #9e7bff;
      border-color: #9e7bff;
    }
  }

  .zfb-info {
    font-size: 3.8vw;
    margin-top: 2vw;
  }
}
</style>