import Vue from 'vue';
import Alipay from './Alipay.vue';

let theAlipay = function (option) {
  let Constructor = Vue.extend(Alipay);
  return new Promise((res, rej) => {
    let DOM = new Constructor({
      el: document.createElement('div')
    })
    document.body.appendChild(DOM.$el);

    DOM.setCode(option.url)

    DOM.ok = function (value) {
      res(value)
    }
    DOM.cancel = function (value) {
      document.body.removeChild(DOM.$el);
    }
  })
}
export default theAlipay;
