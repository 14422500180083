<template>
  <div class="Alipay">
    <van-popup v-model="show" class="popup-box">
      <div class="title">
        <span>支付宝支付</span>
        <van-icon name="cross" @click="show = false" class="vanicon"></van-icon>
      </div>
      <div class="img-box">
        <div v-show="false" id="qrcode" ref="qrcode"></div>
        <img :src="zfbCodeImg" alt="">
      </div>
      <div class="step">
        <p>支付步骤：</p>
        <p>1.长按图片保存</p>
        <p>2.打开支付宝APP，识别该图片的二维码</p>
        <p>3.输入你的支付密码即可</p>
      </div>
    </van-popup>
  </div>

</template>

<script>
import QRCode from 'qrcodejs2'

export default {
  name: 'Alipay',
  data() {
    return {
      show: true,
      zfbCodeImg: '',
    }
  },
  created() { },
  watch: {
    show(newVal) {
      if (newVal === false) {
        this.cancel() // cancel方法在Alipay.js文件实现
      }
    },
  },
  methods: {
    setCode(url) {
      this.$nextTick(() => {
        let qrcode = new QRCode('qrcode', {
          width: 180, // 二维码宽度
          height: 180, // 二维码高度
          text: url,
        })

        // 安卓长按保存兼容
        let canvasDiv = document.getElementById('qrcode')
        let canvas = canvasDiv.querySelector('canvas')
        this.zfbCodeImg = canvas.toDataURL('image/png')
      })
    }
  }
}
</script>

<style lang='less' scoped>
.popup-box {
  width: 80vw;
  border-radius: 10px;
  padding: 12px;
  box-sizing: border-box;

  .title {
    position: relative;
    text-align: center;
    font-size: 4vw;
    font-weight: bold;
    margin-top: 2vw;
    margin-bottom: 4vw;
    .vanicon {
      float: right;
      font-size: 5vw;
    }
  }
  .img-box {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .step {
    font-size: 3.6vw;
    margin-top: 3vw;

    p {
      margin-top: 2px;
    }
  }
}
</style>